export const userConstants = {
    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    GETSINGLE_REQUEST: 'USERS_GETSINGLE_REQUEST',
    GETSINGLE_SUCCESS: 'USERS_GETSINGLE_SUCCESS',
    GETSINGLE_FAILURE: 'USERS_GETSINGLE_FAILURE',

    SET_STATUS_REQUEST: 'USERS_SET_STATUS_REQUEST',
    SET_STATUS_SUCCESS: 'USERS_SET_STATUS_SUCCESS',
    SET_STATUS_FAILURE: 'USERS_SET_STATUS_FAILURE',
};
