import React, { Component, useCallback, useEffect } from "react";
import getImageURL from "../_helpers/imageHelper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "../_config";
import { useDropzone } from 'react-dropzone'

export function MyDropzone({ children, passInputProps, onFilesUpload }) {
    const onDrop = useCallback(acceptedFiles => {
        onFilesUpload && onFilesUpload(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps, /*isDragActive */ } = useDropzone({ onDrop });

    useEffect(() => {
        passInputProps && passInputProps(getInputProps);
    }, [getInputProps])

    return (
        <div {...getRootProps()} className="ctDropZone">
            {children}
        </div>
    )
}

export default class ImageUploader extends Component {
    state = {
        dragInputProps: null,
    }
    onFileUpload = (files) => {
        // files = JSON.parse(JSON.stringify(files));
        let formdata = new FormData();
        formdata.append('image', files[0]);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(config.baseUrl + "/api/upload/image", requestOptions)
            .then(response => response.json())
            .then(result => {
                this.props.onChange(result.filePath);
            })
            .catch(error => console.log('error', error));
    }
    onChangeHandler = event => {
        var files = event.target.files;
        if (this.maxSelectFile(event) && this.checkMimeType(event) && this.checkFileSize(event)) {
            try {
                this.onFileUpload(files)
            } catch (error) {
                toast.error('Invalid Image')
            }
        }
    }
    checkMimeType = (event) => {
        //getting file object
        let files = event.target.files
        //define message container
        let err = []
        // list allow mime type
        const types = ['image/png', 'image/jpeg', 'image/gif', 'image/webp']
        // loop access array
        for (var x = 0; x < files.length; x++) {
            // compare file type find doesn't matach
            if (types.every(type => files[x].type !== type)) {
                // create error message and assign to container   
                err[x] = files[x].type + ' is not a supported format\n';
            }
        };
        for (var z = 0; z < err.length; z++) {// if message not same old that mean has error 
            // discard selected file
            toast.error(err[z])
            event.target.value = null
        }
        return (err.length > 0) ? false : true;
    }
    maxSelectFile = (event) => {
        let files = event.target.files
        if (files.length > 1) {
            const msg = 'Only 1 images can be uploaded at a time'
            event.target.value = null
            toast.warn(msg)
            return false;
        }
        return true;
    }
    checkFileSize = (event) => {
        let files = event.target.files
        let size = 2000000
        let err = [];
        for (var x = 0; x < files.length; x++) {
            if (files[x].size > size) {
                err[x] = files[x].type + 'is too large, please pick a smaller file\n';
            }
        };
        for (var z = 0; z < err.length; z++) {// if message not same old that mean has error 
            // discard selected file
            toast.error(err[z])
            event.target.value = null
        }
        return true;
    }

    render() {
        let { label, value, name, errors, style, className } = this.props;
        const { dragInputProps } = this.state;
        return (
            <MyDropzone passInputProps={(dragInputProps) => this.setState({ dragInputProps })} onFilesUpload={this.onFileUpload}>
                <div className={`form-group ${className}`}>
                    <ToastContainer />
                    <label>{label}</label>
                    <br />
                    <div className="col-sm-12">
                        <img src={getImageURL(value)} alt={label}
                            // style={style ? style : { height: 'auto', width: '100%' }} 
                            style={{ height: 'auto', width: '100%' }}
                        />
                    </div>
                    <div className="col-sm-12">
                        <button type="button" className="btn btn-info text-white" style={{ width: '100%' }} onClick={() => document.getElementById(`image${name}`).click()}>Select or Drag & Drop Image</button>
                        <input type="file" className="form-control mt-1" name={name} id={`image${name}`} onChange={this.onChangeHandler} style={{ display: 'none' }} />

                    </div>

                    {errors ? <div className="alert alert-danger">{errors[0]}</div> : null}
                </div>
            </MyDropzone>
        );
    }
}