import React, { useEffect, useState } from 'react';
import './style.css'

const RatingSelector = ({ value, onChange }) => {
    const [stars, setStars] = useState([]);
    useEffect(() => {
        let tempStars = [];
        let val = value;
        if (!value) {
            val = 3
        }

        for (let i = 1; i <= val; i++) {
            tempStars.push({ id: i, active: true })
        }

        if (val < 5) {
            for (let i = val + 1; i <= 5; i++) {
                tempStars.push({ id: i, active: false })
            }
        }
        setStars(tempStars);

    }, [value])
    return (
        <div className="rating-container">
            {stars.map((s, i) => (<i key={i} className={`${s.active ? 'fas fa-star' : 'far fa-star'}`} onClick={() => onChange && onChange(i + 1)}></i>))}
        </div>
    );
};

export default RatingSelector;