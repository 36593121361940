export default {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer",
      badge: {
        variant: "info",
        text: "NEW"
      }
    },
    {
      name: "Users",
      url: "/users",
      icon: "fa fa-user",
      children: [
        {
          name: "Add User",
          url: "/user/add",
          icon: "fa fa-plus-circle",
          class: 'ml-2'
        },
        {
          name: "Users List",
          url: "/users",
          icon: "fa fa-user",
          class: 'ml-2'
        },
        {
          name: "User KYC's",
          url: "/user-kycs",
          icon: "fa fa-user-lock",
          class: 'ml-2'
        },
       
      ]
    },
 
    {
      name: "User Notifications",
      url: "/user-custom-notifications",
      icon: "fa fa-bell",
      children: [
        {
          name: "Add User Notification",
          url: "/user-custom-notification/add",
          icon: "fa fa-plus-circle",
          class: 'ml-2'
        },
        {
          name: "User Notification List",
          url: "/user-custom-notifications",
          icon: "fa fa-bell",
          class: 'ml-2'
        }
      ]
    },
    {
      name: "Sliders App",
      url: "/sliders",
      icon: "fa fa-image",
      children: [
        {
          name: "Add Slider",
          url: "/slider/add",
          icon: "fa fa-plus-circle",
          class: 'ml-2'
        },
        {
          name: "Sliders List",
          url: "/sliders",
          icon: "fa fa-sitemap",
          class: 'ml-2'
        },
       
      ]
    },
    {
      name: "Categories",
      url: "/categories",
      icon: "fa fa-sitemap",
      children: [
        {
          name: "Add Category",
          url: "/category/add",
          icon: "fa fa-plus-circle",
          class: 'ml-2'
        },
        {
          name: "Categories List",
          url: "/categories",
          icon: "fa fa-sitemap",
          class: 'ml-2'
        },
       
      ]
    },
    {
      name: "Amenities",
      url: "/amenities",
      icon: "fa fa-cogs",
      children: [
        {
          name: "Add Amenity",
          url: "/amenity/add",
          icon: "fa fa-plus-circle",
          class: 'ml-2'
        },
        {
          name: "Amenities List",
          url: "/amenities",
          icon: "fa fa-cogs",
          class: 'ml-2'
        },
       
      ]
    },
    {
      name: "States",
      url: "/states",
      icon: "fa fa-globe",
      children: [
        {
          name: "Add State",
          url: "/state/add",
          icon: "fa fa-plus-circle",
          class: 'ml-2'
        },
        {
          name: "States List",
          url: "/states",
          icon: "fa fa-globe",
          class: 'ml-2'
        },
       
      ]
    },
    {
      name: "Areas",
      url: "/states",
      icon: "fa fa-road",
      children: [
        {
          name: "Add Area",
          url: "/area/add",
          icon: "fa fa-plus-circle",
          class: 'ml-2'
        },
        {
          name: "Area List",
          url: "/areas",
          icon: "fa fa-road",
          class: 'ml-2'
        },
       
      ]
    },
    {
      name: "Properties",
      url: "/properties",
      icon: "fa fa-map-marker",
      children: [
        {
          name: "Add Property",
          url: "/property/add",
          icon: "fa fa-plus-circle",
          class: 'ml-2'
        },
        {
          name: "Property List",
          url: "/properties",
          icon: "fa fa-map-marker",
          class: 'ml-2'
        },
        {
          name: "Unpublished",
          url: '/properties?filter={"isPublished":false}',
          icon: "fa fa-map-marker",
          class: 'ml-2'
        },
       
      ]
    },
    {
      name: "Orders",
      url: "/orders",
      icon: "fa fa-first-order",
      children: [
        {
          name: "Add Order",
          url: "/property/add",
          icon: "fa fa-plus-circle",
          class: 'ml-2'
        },
        {
          name: "Orders List",
          url: "/orders",
          icon: "fa fa-first-order",
          class: 'ml-2'
        },
       
      ]
    },
    {
      // title: true,
      name: 'Setup',
      icon: 'fa fa-wrench',
      children: [
        {
          name: 'Pages',
          url: '/pages',
          icon: 'fa fa-file',
        },
        {
          name: 'Admins',
          url: '/admins',
          icon: 'fa fa-user',
        },
        {
          name: 'Info Settings',
          url: '/infosetting/edit/5f1b75c32686cd198cd9e48f?single=true',
          icon: 'fa fa-wrench',
        },
        {
          name: 'Payment Settings',
          url: '/setting/edit/5ec3bb1bc3c2c6273e2f53bb?single=true',
          icon: 'fa fa-wrench',
        },

      ]
    },

    // {
    //   name: "Users",
    //   url: "/developer/users",
    //   icon: "fa fa-users",
    //   children: [
    //     {
    //       name: "All Users",
    //       url: "/developer/users/",
    //       icon: "fa fa-user-circle",
    //       class: 'ml-2'
    //     },
    //     {
    //       name: "Verified Users",
    //       url: "/developer/user/filter/verified",
    //       icon: "fa fa-check-circle-o",
    //       class: 'ml-2'
    //     },

    //     {
    //       name: "Online Users",
    //       url: "/developer/user/filter/online",
    //       icon: "fa fa-circle",
    //       class: 'ml-2'
    //     },
    //     {
    //       name: "Active Users",
    //       url: "/developer/user/filter/active",
    //       icon: "fa fa-star",
    //       class: 'ml-2'
    //     }
    //   ]
    // },
    // {
    //   name: "Packages",
    //   url: "/developer/packages",
    //   icon: "fa fa-cube",
    //   children: [
    //     {
    //       name: "Categories",
    //       url: "/developer/categories/",
    //       icon: "fa fa-snowflake",
    //       class: 'ml-2'
    //     },
    //     {
    //       name: "Add Package",
    //       url: "/developer/package/add",
    //       icon: "fa fa-plus-circle",
    //       class: 'ml-2'
    //     },
    //     {
    //       name: "Package List",
    //       url: "/developer/packages",
    //       icon: "fa fa-cubes",
    //       class: 'ml-2'
    //     }
    //   ]
    // },
    // {
    //   // title: true,
    //   name: 'Setup',
    //   icon: 'fa fa-wrench',
    //   children: [
    //     {
    //       name: 'Payment Settings',
    //       url: '/developer/payment-settings',
    //       icon: 'fa fa-credit-card', 
    //     },
      
    //   ]
    // },
    // {
    //   name: "Reports",
    //   url: "/developer/theme/colors",
    //   icon: "fa fa-file-text-o"
    // },
    // {
    //   // title: true,
    //   name: "Setup",
    //   icon: "fa fa-wrench",
    //   children: [
    //     {
    //       name: "Basic Settings",
    //       url: "/developer/theme/colors",
    //       icon: "fa fa-wrench"
    //     },
    //     {
    //       name: "Payment Settings",
    //       url: "/developer/theme/colors",
    //       icon: "fa fa-credit-card"
    //     }
    //   ]
    // }

    // {
    //   title: true,
    //   name: 'Theme',
    //   wrapper: {            // optional wrapper object
    //     element: '',        // required valid HTML5 element tag
    //     attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
    //   },
    //   class: ''             // optional class names space delimited list for title item ex: "text-center"
    // },
    // {
    //   name: 'Colors',
    //   url:'/developer/theme/colors',
    //   icon: 'icon-drop',
    // },
    // {
    //   name: 'Typography',
    //   url:'/developer/theme/typography',
    //   icon: 'icon-pencil',
    // },
    // {
    //   title: true,
    //   name: 'Components',
    //   wrapper: {
    //     element: '',
    //     attributes: {},
    //   },
    // },
    // {
    //   name: 'Base',
    //   url:'/developer/base',
    //   icon: 'icon-puzzle',
    //   children: [
    //     {
    //       name: 'Breadcrumbs',
    //       url:'/developer/base/breadcrumbs',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Cards',
    //       url:'/developer/base/cards',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Carousels',
    //       url:'/developer/base/carousels',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Collapses',
    //       url:'/developer/base/collapses',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Dropdowns',
    //       url:'/developer/base/dropdowns',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Forms',
    //       url:'/developer/base/forms',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Jumbotrons',
    //       url:'/developer/base/jumbotrons',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'List groups',
    //       url:'/developer/base/list-groups',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Navs',
    //       url:'/developer/base/navs',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Paginations',
    //       url:'/developer/base/paginations',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Popovers',
    //       url:'/developer/base/popovers',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Progress Bar',
    //       url:'/developer/base/progress-bar',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Switches',
    //       url:'/developer/base/switches',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Tables',
    //       url:'/developer/base/tables',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Tabs',
    //       url:'/developer/base/tabs',
    //       icon: 'icon-puzzle',
    //     },
    //     {
    //       name: 'Tooltips',
    //       url:'/developer/base/tooltips',
    //       icon: 'icon-puzzle',
    //     },
    //   ],
    // },
    // {
    //   name: 'Buttons',
    //   url:'/developer/buttons',
    //   icon: 'icon-cursor',
    //   children: [
    //     {
    //       name: 'Buttons',
    //       url:'/developer/buttons/buttons',
    //       icon: 'icon-cursor',
    //     },
    //     {
    //       name: 'Button dropdowns',
    //       url:'/developer/buttons/button-dropdowns',
    //       icon: 'icon-cursor',
    //     },
    //     {
    //       name: 'Button groups',
    //       url:'/developer/buttons/button-groups',
    //       icon: 'icon-cursor',
    //     },
    //     {
    //       name: 'Brand Buttons',
    //       url:'/developer/buttons/brand-buttons',
    //       icon: 'icon-cursor',
    //     },
    //   ],
    // },
    // {
    //   name: 'Charts',
    //   url:'/developer/charts',
    //   icon: 'icon-pie-chart',
    // },
    // {
    //   name: 'Icons',
    //   url:'/developer/icons',
    //   icon: 'icon-star',
    //   children: [
    //     {
    //       name: 'Home Hunt Icons',
    //       url:'/developer/icons/coreui-icons',
    //       icon: 'icon-star',
    //       badge: {
    //         variant: 'info',
    //         text: 'NEW',
    //       },
    //     },
    //     {
    //       name: 'Flags',
    //       url:'/developer/icons/flags',
    //       icon: 'icon-star',
    //     },
    //     {
    //       name: 'Font Awesome',
    //       url:'/developer/icons/font-awesome',
    //       icon: 'icon-star',
    //       badge: {
    //         variant: 'secondary',
    //         text: '4.7',
    //       },
    //     },
    //     {
    //       name: 'Simple Line Icons',
    //       url:'/developer/icons/simple-line-icons',
    //       icon: 'icon-star',
    //     },
    //   ],
    // },
    // {
    //   name: 'Notifications',
    //   url:'/developer/notifications',
    //   icon: 'icon-bell',
    //   children: [
    //     {
    //       name: 'Alerts',
    //       url:'/developer/notifications/alerts',
    //       icon: 'icon-bell',
    //     },
    //     {
    //       name: 'Badges',
    //       url:'/developer/notifications/badges',
    //       icon: 'icon-bell',
    //     },
    //     {
    //       name: 'Modals',
    //       url:'/developer/notifications/modals',
    //       icon: 'icon-bell',
    //     },
    //   ],
    // },
    // {
    //   name: 'Widgets',
    //   url:'/developer/widgets',
    //   icon: 'icon-calculator',
    //   badge: {
    //     variant: 'info',
    //     text: 'NEW',
    //   },
    // },
    // {
    //   divider: true,
    // },
    // {
    //   title: true,
    //   name: 'Extras',
    // },
    // {
    //   name: 'Pages',
    //   url:'/developer/pages',
    //   icon: 'icon-star',
    //   children: [
    //     {
    //       name: 'Login',
    //       url:'/developer/login',
    //       icon: 'icon-star',
    //     },
    //     {
    //       name: 'Register',
    //       url:'/developer/register',
    //       icon: 'icon-star',
    //     },
    //     {
    //       name: 'Error 404',
    //       url:'/developer/404',
    //       icon: 'icon-star',
    //     },
    //     {
    //       name: 'Error 500',
    //       url:'/developer/500',
    //       icon: 'icon-star',
    //     },
    //   ],
    // },
    // {
    //   name: 'Disabled',
    //   url:'/console',
    //   icon: 'icon-ban',
    //   attributes: { disabled: true },
    // },
    // {
    //   name: 'Download Home Hunt',
    //   url:'/developerhttp://venturexlabs.com/react/',
    //   icon: 'icon-cloud-download',
    //   class: 'mt-auto',
    //   variant: 'success',
    //   attributes: { target: '_blank', rel: "noopener" },
    // },
    // {
    //   name: 'Try Home Hunt PRO',
    //   url:'/developerhttp://venturexlabs.com/pro/react/',
    //   icon: 'icon-layers',
    //   variant: 'danger',
    //   attributes: { target: '_blank', rel: "noopener" },
    // },
  ]
};
