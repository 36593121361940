import React from 'react';
import getImageURL from '../../../_helpers/imageHelper';
import RatingSelector from '../../../_ui_components/RatingSelector';
import config from '../../../_config';
import AddressLocationFieldJS from '../../../_ui_components/AddressLocationField/AddressLocationFieldJS';
const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${config.googleMapAPIKey}&v=3.exp&libraries=geometry,drawing,places`
const FieldViewer = ({ modelType, formObj }) => {
    return (
        <React.Fragment>
            {
                Object.keys(modelType.bluePrint).map((td, key) => {
                    if (!modelType.bluePrint[td].hidden && modelType.bluePrint[td].dataType) {
                        return <tr key={key}>
                            <td style={{ width: '30%' }}>{modelType.bluePrint[td].label}</td>

                            {(modelType.bluePrint[td].dataType === "String" ||
                                modelType.bluePrint[td].dataType === "EnumDropDown" ||
                                modelType.bluePrint[td].dataType === "Number") &&
                                <td key={key}>{formObj[td].toLocaleString()}</td>
                            }
                            {(modelType.bluePrint[td].dataType === "PasswordHash") &&
                                <td key={key} >
                                    *******
                                </td>
                            }

                            {(modelType.bluePrint[td].dataType === "RichText") &&
                                <td key={key} >
                                    <div dangerouslySetInnerHTML={{ __html: formObj[td] }}></div>
                                </td>
                            }
                            {(modelType.bluePrint[td].dataType === "AddressBar") &&
                                <td key={key}>
                                    <div classNme="row">
                                        <div className="col-sm-12">{formObj[td]}</div>
                                        {
                                            formObj[td + "Location"] && <div className="col-sm-12 mt-2">
                                                <AddressLocationFieldJS viewable
                                                    location={formObj[td + "Location"]} />
                                            </div>
                                        }

                                    </div>
                                </td>
                            }

                            {(modelType.bluePrint[td].dataType === "Image") && <td key={key}>
                                <img src={getImageURL(formObj[td])} alt={td} style={{ height: 100 }} />
                            </td>}

                            {(modelType.bluePrint[td].dataType === "MultipleImages") && <td key={key}>
                                <div className="row">
                                    {formObj[td].map(item => <div className="col-sm-4">
                                        <img src={getImageURL(item.path)} alt={td} style={{ width: '100%', height: 'auto' }} />
                                    </div>
                                    )}
                                </div>
                            </td>}

                            {(modelType.bluePrint[td].dataType === "Boolean") &&
                                <td key={key}>{formObj[td] ? "Yes" : "No"}</td>
                            }


                            {(modelType.bluePrint[td].dataType === "ParentDropDown") &&
                                <td key={key}>
                                    {(formObj[td + "Obj"] && formObj[td + "Obj"][modelType.bluePrint[td].displayfield]) ? formObj[td + "Obj"][modelType.bluePrint[td].displayfield] : null}
                                </td>
                            }

                            {(modelType.bluePrint[td].dataType === "ParentCheckList") &&
                                <td key={key}>
                                    {formObj[td].map(li => <li>
                                        {li.obj[modelType.bluePrint[td].displayfield]}
                                    </li>)}
                                </td>
                            }

                            {(modelType.bluePrint[td].dataType === "Date" || modelType.bluePrint[td].dataType === "DateTime" || modelType.bluePrint[td].dataType === "DefaultDate") &&
                                <td key={key}>{new Date(formObj[td]).toLocaleDateString()} {new Date(formObj[td]).toLocaleTimeString()}</td>
                            }

                            {(modelType.bluePrint[td].dataType === "RatingSelector") &&
                                <td key={key} >
                                    <RatingSelector value={formObj[td]} />
                                </td>
                            }

                            {(modelType.bluePrint[td].dataType === "ChildGrid") &&
                                <td key={key} >
                                    {formObj[td].map(li => <div className="mb-4">
                                        <FieldViewer modelType={modelType.bluePrint[td]} formObj={li} />
                                    </div>)}
                                </td>
                            }
                        </tr>
                    } else
                        return null;
                })
            }
        </React.Fragment>
    );
};

export default FieldViewer;