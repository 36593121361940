export const userCustomNotificationConstants = {
    GETALL_REQUEST: 'USER_CUSTOM_NOTIFICATIONS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USER_CUSTOM_NOTIFICATIONS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USER_CUSTOM_NOTIFICATIONS_GETALL_FAILURE',

    GETSINGLE_REQUEST: 'USER_CUSTOM_NOTIFICATIONS_GETSINGLE_REQUEST',
    GETSINGLE_SUCCESS: 'USER_CUSTOM_NOTIFICATIONS_GETSINGLE_SUCCESS',
    GETSINGLE_FAILURE: 'USER_CUSTOM_NOTIFICATIONS_GETSINGLE_FAILURE',

    ADD_REQUEST: 'USER_CUSTOM_NOTIFICATIONS_ADD_REQUEST',
    ADD_SUCCESS: 'USER_CUSTOM_NOTIFICATIONS_ADD_SUCCESS',
    ADD_FAILURE: 'USER_CUSTOM_NOTIFICATIONS_ADD_FAILURE',

    UPDATE_REQUEST: 'USER_CUSTOM_NOTIFICATIONS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USER_CUSTOM_NOTIFICATIONS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USER_CUSTOM_NOTIFICATIONS_UPDATE_FAILURE',

    DELETE_REQUEST: 'USER_CUSTOM_NOTIFICATIONS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USER_CUSTOM_NOTIFICATIONS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USER_CUSTOM_NOTIFICATIONS_DELETE_FAILURE',
};
