import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";

import { userPackagesService } from "../../../_services/userPackages.service";
import { settingsService } from "../../../_services/settings.service";
import { connect } from "react-redux";
import _config from "../../../_config/";
import PaginationComponent from "react-reactstrap-pagination";
import Field from '../../../_common/field';
import config from '../../../_config';

const defaultImage = "https://c7.uihere.com/icons/22/702/390/staff-channel-app-default-0c2f68d42cf81f4f6cedf5fc0c6894d8.png";
function PackageRow(props) {
  const packageObj = props.obj;

  const getBadge = status => {
    return status == 'active' ? "success" : "danger";
  };
  const getImageURL = (image) => {
    return image ? image.includes("http") || image.includes("base64") ? image : config.baseUrl + image : defaultImage;
  }

  return (
    <tr key={packageObj._id.toString()}>
      <th><img src={getImageURL(packageObj.package.image)} style={{ height: '50px', width: 'auto' }} /></th>
      <td>{packageObj.package.title}</td>
      <td>{packageObj.package.amount}</td>
      <td>{packageObj.package.duration} ({packageObj.package.duration > 1 ? "Days" : "Day"})</td>
      <td>{packageObj.package.type}</td>
      <td>{new Date(packageObj.activatedOn).toLocaleDateString()}</td>
      <td>{new Date(packageObj.expireOn).toLocaleDateString()}</td>
      <td>
        <Badge color={getBadge(packageObj.status)} style={{ width: 60 }}>
          {packageObj.status}
        </Badge>
      </td>

    </tr>
  );
}

class UserPackages extends Component {
  state = {
    activePagination: {
      pageLoading: true,
      selectedPage: 1,
      pageSize: 10,
      maxPaginationNumbers: 9,
    },
    type: undefined,
    searching: false,
    packageList: [],
    filteredPackageList: [],
  };
  componentDidMount() {
    userPackagesService.getAll(this.props.match.params.id).then(packageList => {
      this.setState({ packageList, filteredPackageList: packageList })
    })
  }

  handleSelectedActivePage = selectedPage => {
    let activePagination = this.state.activePagination;
    activePagination.selectedPage = selectedPage;
    this.setState({ activePagination });
  };

  doSearch = ({ target }) => {
    console.log("doSearch")
    if (!this.state.searching) {
      this.setState({ searching: true })
      const { name, value } = target;
      let filteredPackageList = this.state.packageList;
      // let filteredPackageList = JSON.parse(JSON.stringify(this.state.packageList));
      let searchText = value.toLowerCase();
      if (filteredPackageList && filteredPackageList.length > 0) {
        filteredPackageList = filteredPackageList.filter(u =>
          (u.package.title && u.package.title.toLowerCase().includes(searchText)) ||
          (u.package.type && u.package.type.toLowerCase().includes(searchText)) ||
          (u.package.duration && u.package.duration + "" == searchText) ||
          (u.package.amount && u.package.amount + "" == searchText) ||
          (u.expireOn && new Date(u.expireOn).toLocaleDateString().includes(searchText))||
          (u.activatedOn && new Date(u.activatedOn).toLocaleDateString().includes(searchText))
        );
      }
      this.setState({ filteredPackageList, searching: false })
    }



  }
  render() {
    // const packageList = packagesData
    const { packageList, type, filteredPackageList } = this.state;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xl={12}>
            <Card>
              <CardHeader>
                <div className="row">
                  <div className="col-sm-9">
                    <i className="fa fa-cubes"></i> User Packages

                  </div>
                  <div className="col-sm-3" style={{ textAlign: 'right' }}>
                    <Link to="/users" className="btn btn-primary btn-sm">
                      Back to Users
                    </Link>
                  </div>
                </div>

              </CardHeader>
              <CardBody>
                <Field>
                  <input
                    name="search"
                    type="text"
                    placeholder="Search..."
                    disabled={this.state.searching}
                    onChange={e => this.doSearch(e)}
                  />
                </Field>
                <Table responsive hover>
                  <thead>
                    <tr>
                      {/* <th scope="col">id</th> */}
                      <th scope="col"></th>
                      <th scope="col">Title</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Duration</th>
                      <th scope="col">Type</th>
                      <th scope="col">Activation Date</th>
                      <th scope="col">Expire Date</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPackageList &&
                      filteredPackageList.length > 0 ?
                      filteredPackageList
                        .slice(
                          (this.state.activePagination.selectedPage - 1) *
                          this.state.activePagination.pageSize,
                          this.state.activePagination.selectedPage *
                          this.state.activePagination.pageSize
                        )
                        .map((packageObj, index) => (
                          <PackageRow key={index} obj={packageObj} />
                        ))
                      :
                      <tr>
                        <td>No Package Found!</td>
                      </tr>
                    }

                  </tbody>
                </Table>
                {
                  filteredPackageList && <PaginationComponent
                    totalItems={filteredPackageList.length}
                    pageSize={this.state.activePagination.pageSize}
                    onSelect={this.handleSelectedActivePage}
                    maxPaginationNumbers={
                      this.state.activePagination.maxPaginationNumbers
                    }
                    activePage={this.state.activePagination.selectedPage}
                  />
                }

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { packages } = state;
  // const { token, admin } = authentication;
  return {
    packages
  };
}

const connected = connect(mapStateToProps)(UserPackages);
export { connected as UserPackages };
