/* eslint-disable import/first */
import React from "react";
import { User } from "./_pages/Authenticated/Users/User";
import { Users } from "./_pages/Authenticated/Users/Users";
import { UserPackages } from "./_pages/Authenticated/Users/UserPackages";
import { DataTable, Detail, Manage, } from "./_pages/Authenticated/CRUD";

// import { DataTable as Categories, Detail as Category , Manage as CategoryManage ,setRoute as setRouteCategory} from "./_pages/Authenticated/CRUD";
// import { DataTable as Amenities, Detail as Amenity , Manage as AmenityManage ,setRoute as setRouteAmenity} from "./_pages/Authenticated/CRUD";

// import { CategoryManage } from "./_pages/Authenticated/Categories/CategoryManage";
// import { Category } from "./_pages/Authenticated/Categories/Category";
import { AdminManage } from "./_pages/Authenticated/Admin/AdminManage";
import PaymentSettings from './_pages/Authenticated/Settings/PaymentSettings';
import { UserCustomNotifications } from "./_pages/Authenticated/UserCustomNotifications/UserCustomNotifications";
import { UserCustomNotification } from "./_pages/Authenticated/UserCustomNotifications/UserCustomNotification";
import { UserCustomNotificationManage } from "./_pages/Authenticated/UserCustomNotifications/UserCustomNotificationManage";

// setRouteAmenity("amenities");
const Breadcrumbs = React.lazy(() => import("./views/Base/Breadcrumbs"));
const Cards = React.lazy(() => import("./views/Base/Cards"));
const Carousels = React.lazy(() => import("./views/Base/Carousels"));
const Collapses = React.lazy(() => import("./views/Base/Collapses"));
const Dropdowns = React.lazy(() => import("./views/Base/Dropdowns"));
const Forms = React.lazy(() => import("./views/Base/Forms"));
const Jumbotrons = React.lazy(() => import("./views/Base/Jumbotrons"));
const ListGroups = React.lazy(() => import("./views/Base/ListGroups"));
const Navbars = React.lazy(() => import("./views/Base/Navbars"));
const Navs = React.lazy(() => import("./views/Base/Navs"));
const Paginations = React.lazy(() => import("./views/Base/Paginations"));
const Popovers = React.lazy(() => import("./views/Base/Popovers"));
const ProgressBar = React.lazy(() => import("./views/Base/ProgressBar"));
const Switches = React.lazy(() => import("./views/Base/Switches"));
const Tables = React.lazy(() => import("./views/Base/Tables"));
const Tabs = React.lazy(() => import("./views/Base/Tabs"));
const Tooltips = React.lazy(() => import("./views/Base/Tooltips"));
const BrandButtons = React.lazy(() => import("./views/Buttons/BrandButtons"));
const ButtonDropdowns = React.lazy(() =>
  import("./views/Buttons/ButtonDropdowns")
);
const ButtonGroups = React.lazy(() => import("./views/Buttons/ButtonGroups"));
const Buttons = React.lazy(() => import("./views/Buttons/Buttons"));
const Charts = React.lazy(() => import("./views/Charts"));
const Dashboard = React.lazy(() => import("./views/Dashboard"));
const CoreUIIcons = React.lazy(() => import("./views/Icons/CoreUIIcons"));
const Flags = React.lazy(() => import("./views/Icons/Flags"));
const FontAwesome = React.lazy(() => import("./views/Icons/FontAwesome"));
const SimpleLineIcons = React.lazy(() =>
  import("./views/Icons/SimpleLineIcons")
);
const Alerts = React.lazy(() => import("./views/Notifications/Alerts"));
const Badges = React.lazy(() => import("./views/Notifications/Badges"));
const Modals = React.lazy(() => import("./views/Notifications/Modals"));
const Colors = React.lazy(() => import("./views/Theme/Colors"));
const Typography = React.lazy(() => import("./views/Theme/Typography"));
const Widgets = React.lazy(() => import("./views/Widgets/Widgets"));

// const Console = React.lazy(() => import("./views/Console"));
const routes = [
  { path: "/admin", exact: true, name: "Home" },
  { path: "/manage", exact: true, name: "Admin Manage", component: AdminManage },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },

  // { path: "/users", exact: true, name: "Users", component: Users },
  // { path: "/user/:id/packages", exact: true, name: "User Packages", component: UserPackages },
  // { path: "/user/filter/:type", exact: true, name: "Filter Users", component: Users },
  // { path: "/users/:id", exact: true, name: "User Details", component: User },

  { path: "/users", exact: true, component: DataTable },
  { path: "/user/add", exact: true, name: "Add Users", component: Manage },
  { path: "/user/edit/:id", exact: true, name: "Edit Users", component: Manage },
  { path: "/users/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/users/detail/:id", exact: true, name: "Details", component: Detail },


  { path: "/sliders", exact: true, component: DataTable },
  { path: "/slider/add", exact: true, name: "Add Slider", component: Manage },
  { path: "/slider/edit/:id", exact: true, name: "Edit Slider", component: Manage },
  { path: "/sliders/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/sliders/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/categories", exact: true, component: DataTable },
  { path: "/category/add", exact: true, name: "Add Categories", component: Manage },
  { path: "/category/edit/:id", exact: true, name: "Edit Categories", component: Manage },
  { path: "/categories/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/categories/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/amenities", exact: true, component: DataTable },
  { path: "/amenity/add", exact: true, name: "Add Amenities", component: Manage },
  { path: "/amenity/edit/:id", exact: true, name: "Edit Amenities", component: Manage },
  { path: "/amenities/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/amenities/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/states", exact: true, component: DataTable },
  { path: "/state/add", exact: true, name: "Add State", component: Manage },
  { path: "/state/edit/:id", exact: true, name: "Edit State", component: Manage },
  { path: "/states/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/states/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/areas", exact: true, component: DataTable },
  { path: "/area/add", exact: true, name: "Add Area", component: Manage },
  { path: "/area/edit/:id", exact: true, name: "Edit Area", component: Manage },
  { path: "/areas/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/areas/detail/:id", exact: true, name: "Details", component: Detail },


  { path: "/agent-details", exact: true, component: DataTable },
  { path: "/agent-detail/add", exact: true, name: "Add Agent Detail", component: Manage },
  { path: "/agent-detail/edit/:id", exact: true, name: "Edit Agent Detail", component: Manage },
  { path: "/agent-details/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/agent-details/detail/:id", exact: true, name: "Details", component: Detail },


  { path: "/properties", exact: true, component: DataTable },
  { path: "/property/add", exact: true, name: "Add Property", component: Manage },
  { path: "/property/edit/:id", exact: true, name: "Edit Property", component: Manage },
  { path: "/properties/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/properties/detail/:id", exact: true, name: "Details", component: Detail },


  { path: "/propertyvisits", exact: true, component: DataTable },
  { path: "/propertyvisit/add", exact: true, name: "Add Property Visit", component: Manage },
  { path: "/propertyvisit/edit/:id", exact: true, name: "Edit Property Visit", component: Manage },
  { path: "/propertyvisits/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/propertyvisits/detail/:id", exact: true, name: "Details", component: Detail },


  { path: "/orders", exact: true, component: DataTable },
  { path: "/order/add", exact: true, name: "Add Order", component: Manage },
  { path: "/order/edit/:id", exact: true, name: "Edit Order", component: Manage },
  { path: "/orders/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/orders/detail/:id", exact: true, name: "Details", component: Detail },


  { path: "/orderstatuses", exact: true, component: DataTable },
  { path: "/orderstatuses/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/orderstatuses/detail/:id", exact: true, name: "Details", component: Detail },
  { path: "/orderstatus/add", exact: true, name: "Add Order", component: Manage },
  { path: "/orderstatus/edit/:id", exact: true, name: "Edit Order", component: Manage },


  { path: "/user-kycs", exact: true, component: DataTable },
  { path: "/user-kycs/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/user-kycs/detail/:id", exact: true, name: "Details", component: Detail },
  { path: "/user-kyc/add", exact: true, name: "Add User KYC", component: Manage },
  { path: "/user-kyc/edit/:id", exact: true, name: "Edit KYC", component: Manage },

  { path: "/user-custom-notifications", exact: true, name: "User Custom Notifications", component: UserCustomNotifications },
  { path: "/user-custom-notifications/detail/:id", exact: true, name: "Details", component: UserCustomNotification },
  { path: "/user-custom-notifications/delete/:id", exact: true, name: "Delete", component: UserCustomNotification },
  { path: "/user-custom-notification/add", exact: true, name: "New Custom Notification", component: UserCustomNotificationManage },
  { path: "/user-custom-notification/edit/:id", exact: true, name: "Edit User Custom Notification", component: UserCustomNotificationManage },


  { path: "/modules", exact: true, component: DataTable },
  { path: "/module/add", exact: true, name: "Add Module", component: Manage },
  { path: "/module/edit/:id", exact: true, name: "Edit Module", component: Manage },
  { path: "/modules/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/modules/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/pages", exact: true, component: DataTable },
  { path: "/page/add", exact: true, name: "Add Page", component: Manage },
  { path: "/page/edit/:id", exact: true, name: "Edit Page", component: Manage },
  { path: "/pages/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/pages/detail/:id", exact: true, name: "Details", component: Detail },
  // { path: '/payment-settings', exact: true, name: 'Payment Settings', component: PaymentSettings },



  { path: "/sales-agents", exact: true, component: DataTable },
  { path: "/sales-agent/add", exact: true, name: "Add Sales Agent", component: Manage },
  { path: "/sales-agent/edit/:id", exact: true, name: "Edit Sales Agent", component: Manage },
  { path: "/sales-agents/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/sales-agents/detail/:id", exact: true, name: "Details", component: Detail },


  { path: "/admins", exact: true, component: DataTable },
  { path: "/admin/add", exact: true, name: "Add Admin", component: Manage },
  { path: "/admin/edit/:id", exact: true, name: "Edit Admin", component: Manage },
  { path: "/admins/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/admins/detail/:id", exact: true, name: "Details", component: Detail },



  { path: "/infosetting/edit/:id", exact: true, name: "Info Setting", component: Manage },
  { path: "/setting/edit/:id", exact: true, name: "Settings", component: Manage },
  { path: "/theme", exact: true, name: "Theme", component: Colors },
  { path: "/theme/colors", name: "Colors", component: Colors },
  { path: "/theme/typography", name: "Typography", component: Typography },
  { path: "/base", exact: true, name: "Base", component: Cards },
  { path: "/base/cards", name: "Cards", component: Cards },
  { path: "/base/forms", name: "Forms", component: Forms },
  { path: "/base/switches", name: "Switches", component: Switches },
  { path: "/base/tables", name: "Tables", component: Tables },
  { path: "/base/tabs", name: "Tabs", component: Tabs },
  { path: "/base/breadcrumbs", name: "Breadcrumbs", component: Breadcrumbs },
  { path: "/base/carousels", name: "Carousel", component: Carousels },
  { path: "/base/collapses", name: "Collapse", component: Collapses },
  { path: "/base/dropdowns", name: "Dropdowns", component: Dropdowns },
  { path: "/base/jumbotrons", name: "Jumbotrons", component: Jumbotrons },
  { path: "/base/list-groups", name: "List Groups", component: ListGroups },
  { path: "/base/navbars", name: "Navbars", component: Navbars },
  { path: "/base/navs", name: "Navs", component: Navs },
  { path: "/base/paginations", name: "Paginations", component: Paginations },
  { path: "/base/popovers", name: "Popovers", component: Popovers },
  { path: "/base/progress-bar", name: "Progress Bar", component: ProgressBar },
  { path: "/base/tooltips", name: "Tooltips", component: Tooltips },
  { path: "/buttons", exact: true, name: "Buttons", component: Buttons },
  { path: "/buttons/buttons", name: "Buttons", component: Buttons },
  {
    path: "/buttons/button-dropdowns",
    name: "Button Dropdowns",
    component: ButtonDropdowns
  },
  {
    path: "/buttons/button-groups",
    name: "Button Groups",
    component: ButtonGroups
  },
  {
    path: "/buttons/brand-buttons",
    name: "Brand Buttons",
    component: BrandButtons
  },
  { path: "/icons", exact: true, name: "Icons", component: CoreUIIcons },
  {
    path: "/icons/coreui-icons",
    name: "Bet-Link Icons",
    component: CoreUIIcons
  },
  { path: "/icons/flags", name: "Flags", component: Flags },
  { path: "/icons/font-awesome", name: "Font Awesome", component: FontAwesome },
  {
    path: "/icons/simple-line-icons",
    name: "Simple Line Icons",
    component: SimpleLineIcons
  },
  {
    path: "/notifications",
    exact: true,
    name: "Notifications",
    component: Alerts
  },
  { path: "/notifications/alerts", name: "Alerts", component: Alerts },
  { path: "/notifications/badges", name: "Badges", component: Badges },
  { path: "/notifications/modals", name: "Modals", component: Modals },
  { path: "/widgets", name: "Widgets", component: Widgets },
  { path: "/charts", name: "Charts", component: Charts }
];

export default routes;
