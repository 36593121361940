import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table, FormGroup, Input } from 'reactstrap';
import { userActions } from '../../../_actions/user.actions';
import { connect } from 'react-redux';
import config from '../../../_config';

function DataCard(props) {
  let { col, label, count, status, icon } = props;
  return <div className={`col-${col}`}>
    <Card className={`text-white bg-${status} text-center`} style={{ height: 60, paddingTop: 5 }}>
      <div className="text-center" style={{ display: "table" }}>
        <div className="row">
          <div className="col-6">
            <i className={`fa fa-${icon}`}></i>
            <br />
            <span style={{ fontSize: 14 }}>{label}</span>
          </div>
          <div className="col-6">
            <span style={{ fontSize: 26 }}>{count}</span>
          </div>
        </div>
      </div>
    </Card>
  </div>
}
class User extends Component {
  state = {
    status: false,
    verified: false
  }

  componentDidMount() {
    console.log(this.props.match.params.id)
    this.props.dispatch(userActions.getSingle(this.props.match.params.id)).then(user => {
      console.log(user);
      this.setState({ status: user.status, verified: user.verified })

    }).catch(e => {
      console.log(e)
    })


  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.users !== this.props.users) {
      // Do whatever you want
      if (this.props.users.single) {
        // this.setState({ status: this.props.users.single.status, verified: this.props.users.single.verified })
      }
    }
  }
  getImageURL(image) {
    return image ? image.includes("http") ? image : config.baseUrl + image : "https://png.pngtree.com/element_our/png/20181206/users-vector-icon-png_260862.jpg";
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if (name === "status") {
      this.props.dispatch(userActions.setStatus(this.props.match.params.id, value)).then(r => {
        alert("Status successfully change!");
      })
    }
    if (name === "verified") {
      this.props.dispatch(userActions.setVerificationStatus(this.props.match.params.id, value)).then(r => {
        alert("Verification Status successfully change!");
      })
    }
  }

  render() {
    const user = this.props.users && this.props.users.single ? this.props.users.single : null;
    const loading = this.props.users.loading;
    const getBadge = (status) => {
      return status ? 'success' : 'danger'
    }

    return (
      <div>
        {
          loading ? <span><i className="text-muted icon-wait"></i> Loading...</span> :
            user ? <div className="animated fadeIn" >
              <Row>
                <Col lg={4}>
                  <Card>
                    <CardHeader>
                      <div className="row">
                        <div className="col-10">
                          <strong><i className="fa fa-user pr-1"></i>User</strong>
                        </div>
                        <div className="col-2">
                          <button onClick={() => window.print()} className="btn btn-primary btn-sm" style={{ float: 'right' }}>
                            <i className="fa fa-print"></i>
                          </button>
                        </div>
                      </div>


                    </CardHeader>
                    <CardBody>
                      <Table responsive striped hover>
                        <tbody>
                          <tr>
                            <td>Profile Image: </td><td><img src={this.getImageURL(user.profileImage)} className="img-thumbnail" style={{ width: '75%' }} /></td>
                          </tr>
                          <tr>
                            <td>Name: </td><td><strong>{user.firstName} {user.lastName}</strong></td>
                          </tr>
                          <tr>
                            <td>Email: </td>
                            <td
                              title={user.isEmailConfirmed ? "Email Verified" : "Email Not Verified"}>
                              <strong>{user.email}</strong>
                              {user.isEmailConfirmed ? (
                                <i
                                  className="fa fa-check-circle-o ml-1"
                                  aria-hidden="true"
                                  style={{ color: "green" }}
                                ></i>
                              ) : (
                                  <i
                                    className="fa fa-exclamation-circle ml-1 text-warning"
                                    aria-hidden="true"
                                  ></i>
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>Phone Number: </td>
                            <td
                              title={user.isPhoneNoConfirmed ? "Phone Number Verified" : "Phone Number Not Verified"}>
                              <strong>{user.phoneNo ? user.phoneNo : "Not Set"}</strong>
                              {user.isPhoneNoConfirmed ? (
                                <i
                                className="fa fa-check-circle-o ml-1"
                                  aria-hidden="true"
                                  style={{ color: "green" }}
                                ></i>
                              ) : (
                                  <i
                                    className="fa fa-exclamation-circle ml-1 text-warning"
                                    aria-hidden="true"
                                  ></i>
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>Social Authentication: </td>
                            <td>
                              {user.source && user.source[0].toUpperCase() + user.source.slice(1)}
                            </td>
                          </tr>
                          <tr>
                            <td>Availability: </td>
                            <td>
                              {user.isOnline ? "Online" : "Offline"}
                            </td>
                          </tr>
                          <tr>
                            <td>Status: </td>
                            <td>
                              <FormGroup>
                                <Col md="10" >
                                  <Input type="select" name="status" id="select" value={this.state.status} onChange={this.handleChange} style={{ marginLeft: -20 }}>
                                    <option value={true}>Active</option>
                                    <option value={false}>InActive</option>
                                  </Input>
                                </Col>
                              </FormGroup>
                            </td>
                          </tr>
                          <tr>
                            <td>Verified: </td>
                            <td>
                              <FormGroup>
                                <Col md="10" >
                                  <Input type="select" name="verified" id="select" value={this.state.verified} onChange={this.handleChange} style={{ marginLeft: -20 }}>
                                    <option value={true}>Verified</option>
                                    <option value={false}>UnVerified</option>
                                  </Input>
                                </Col>
                              </FormGroup>
                            </td>
                          </tr>


                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg={8}>
                  <Card>
                    <CardHeader>
                      <strong><i className="fa fa-line-chart pr-1"></i>Activity</strong>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <DataCard col={3} label="Clients" icon="user" count={user.clients} status="info" />
                        <DataCard col={3} label="Groups" icon="users" count={user.groups} status="primary" />
                        <DataCard col={3} label="Agendas" icon="calendar-check-o" count={user.appointments} status="warning" />
                        <DataCard col={3} label="Tasks" icon="list" count={user.tasks} status="danger" />
                      </Row>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardHeader>
                      <strong><i className="icon-info pr-1"></i>Documents</strong>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg={6}>
                          <h5>Identity Document</h5>
                          {
                            user.idDocumentURL ?
                              <img src={this.getImageURL(user.idDocumentURL)} className="img-fluid" /> :
                              <span>Identity Document not uploaded yet!</span>
                          }

                        </Col>
                        <Col lg={6}>
                          <h5>Utility Document</h5>
                          {
                            user.utilityDocumentURL ?
                              <img src={this.getImageURL(user.utilityDocumentURL)} className="img-fluid" /> :
                              <span>Utility Document not uploaded yet!</span>
                          }
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                </Col>
              </Row>
            </div >
              : <span><i className="text-muted icon-ban"></i> Not found</span>
        }
      </div>
    )

  }
}

function mapStateToProps(state) {
  const { users } = state;
  // const {token, admin} = authentication;
  return {
    users
  };
}

const connected = connect(mapStateToProps)(User);
export { connected as User }; 
