import pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';
import FieldsGenerator from '../../_pages/Authenticated/CRUD/FieldsGenerator';
import './style.css'

const ChildGrid = ({ name, bluePrint, value, errors, isLoading, onChange }) => {
    const [childBluePrint, setChildBluePrint] = useState(bluePrint.bluePrint);
    const [cgErrors, setCgError] = useState({});

    useEffect(() => {
        console.log({ childBluePrint });
        if (!value || value.length === 0) {
            newRow();
        }
    }, []);

    const newRow = (val = []) => {
        let formObj = {};
        Object.keys(childBluePrint).forEach(attribute => {
            if (!childBluePrint[attribute].hidden && (childBluePrint[attribute].dataType === "Date" || childBluePrint[attribute].dataType === "DefaultDate")) {
                formObj[attribute] = new Date();
            } else {
                formObj[attribute] = childBluePrint[attribute].default;
            }
        });
        val.push(formObj);
        onChange(val);
    }


    const handleChageCustom = (name, val, index) => {
        value[index][name] = val;
        onChange(value);
    }

    const deleteRow = (i) => {
        value.splice(i, 1);
        onChange(value);
    }
    return (
        <div className="form-group">
            {/* <label>{bluePrint.label}</label> */}
            <div className="cg-container">


                {
                    value && value.length > 0 && value.map((v, index) => <div className="cg-item">
                        <h6 className="">
                            {pluralize.singular(bluePrint.label)} {index + 1}
                        </h6>
                        <div className="cg-field cg-button-field">
                            <button
                                disabled={index !== value.length - 1}
                                className="btn btn-info btn-sm" onClick={() => newRow(value)} type="button">
                                <i className="fa fa-plus"></i>
                            </button>


                        </div>
                        <div className="cg-field cg-button-field">
                            {value.length > 1 && <button className="btn btn-sm btn-danger" type="button" onClick={() => deleteRow(index)}>
                                <i className="fas fa-trash"></i>
                            </button>
                            }

                        </div>
                        <FieldsGenerator
                            modelType={{ bluePrint: childBluePrint }}
                            key={index}
                            errors={cgErrors}
                            formObj={v}
                            handleChange={({ target }) => handleChageCustom(target.name, target.value, index)}
                            handleChageCustom={(name, value) => handleChageCustom(name, value, index)}
                            handleChangeBoolean={({ target }) => handleChageCustom(target.name, !value[target.name], index)}
                            isLoading={isLoading}
                            onchangeModelType={(modelType) => setChildBluePrint(modelType)}
                            className="cg-field"
                        />
                    </div>
                    )
                }




            </div>
        </div>
    );
};

export default ChildGrid;