import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";

import { userActions } from "../../../_actions/user.actions";
import { userService } from "../../../_services/user.service";
import { connect } from "react-redux";
import _config from "../../../_config/";
import PaginationComponent from "react-reactstrap-pagination";
import Field from '../../../_common/field';

function UserRow(props) {
  const user = props.user;
  const userLink = `/users/${user._id}`;
  const userPackageLink = `/user/${user._id}/packages`;

  const getBadge = status => {
    // return status === 'Active' ? 'success' :
    //     status === 'Inactive' ? 'secondary' :
    //         status === 'Pending' ? 'warning' :
    //             status === 'Banned' ? 'danger' :
    //                 'primary'

    return status ? "success" : "danger";
  };

  return (
    <tr key={user._id.toString()}>
      {/* <th scope="row"><Link to={userLink}>{user.id}</Link></th> */}
      <td>
        <Link to={userLink}>
          {user.firstName} {user.lastName}
        </Link>
      </td>

      <td
        title={user.isEmailConfirmed ? "Email Verified" : "Email Not Verified"}
      >
        {user.email}

      </td>
      <td>
        {user.isEmailConfirmed ? (
          <i
            class="fa fa-check-circle-o ml-1"
            aria-hidden="true"
            style={{ color: "green" }}
          ></i>
        ) : (
            <i
              className="fa fa-exclamation-circle ml-1 text-warning"
              aria-hidden="true"
            ></i>
          )}
      </td>
      <td
        title={user.isPhoneNoConfirmed ? "Phone Number Verified" : "Phone Number Not Verified"}
      >
        {user.phoneNo ? user.phoneNo : "Not Set"}

      </td>
      <td>
        {user.isPhoneNoConfirmed ? (
          <i
            className="fa fa-check-circle-o ml-1"
            aria-hidden="true"
            style={{ color: "green" }}
          ></i>
        ) : (
            <i
              className="fa fa-exclamation-circle ml-1 text-warning"
              aria-hidden="true"
            ></i>
          )}
      </td>
      <td>{user.source[0].toUpperCase() + user.source.slice(1)}</td>
      <td>{user.appointments}</td>
      <td>{user.tasks}</td>
      <td>
        <Link to={userLink}>
          <Badge color={getBadge(user.verified)} style={{ width: 60 }}>
            {user.verified ? "Verified" : "Unverified"}
          </Badge>
        </Link>
      </td>
      <td>
        <Link to={userLink}>
          <Badge color={getBadge(user.isOnline)} style={{ width: 60 }}>
            {user.isOnline ? "Online" : "Offline"}
          </Badge>
        </Link>
      </td>
      <td>
        <Link to={userLink}>
          <Badge color={getBadge(user.status)} style={{ width: 60 }}>
            {user.status ? "Active" : "Inactive"}
          </Badge>
        </Link>
      </td>
      <td>
        <Link to={userPackageLink} className="btn btn-info btn-sm text-white">
          Packages ({user.packageActivations ? user.packageActivations.length : 0})
        </Link>
      </td>

    </tr>
  );
}

class Users extends Component {
  state = {
    activePagination: {
      pageLoading: true,
      selectedPage: 1,
      pageSize: 10,
      maxPaginationNumbers: 9,
      exportingExcel: false,
      userList: [],
      filteredUserList: [],
      type: undefined,
      searching: false
    }
  };
  componentDidMount() {
    var { type } = this.props.match.params;
    this.props.dispatch(userActions.getAll()).then(m => {
      let userList = this.props.users && this.props.users.list ? this.props.users.list : [];
      if (type && type === 'active') {
        userList = userList.filter(g => g.status);
      } else if (type && type === 'verified') {
        userList = userList.filter(g => g.verified);
      } else if (type && type === 'online') {
        userList = userList.filter(g => g.isOnline);
      }
      this.setState({ userList, type, filteredUserList: userList })
    });
  }
  componentDidUpdate() {
    if (this.state.userList) {
      setTimeout(() => {
        var { type } = this.props.match.params;
        let userList = JSON.parse(JSON.stringify(this.state.userList));
        if (type && type === 'active') {
          userList = userList.filter(g => g.status);
        } else if (type && type === 'verified') {
          userList = userList.filter(g => g.verified);
        } else if (type && type === 'online') {
          userList = userList.filter(g => g.isOnline);
        }
        this.setState({ type, filteredUserList: userList })
      }, 300)

    }
  }
  handleSelectedActivePage = selectedPage => {
    let activePagination = this.state.activePagination;
    activePagination.selectedPage = selectedPage;
    this.setState({ activePagination });
  };
  handleExportExcel = () => {
    this.setState({ exportingExcel: true });
    userService.exportExcel(this.props.users && this.props.users.list ? this.props.users.list : []).then(m => {
      this.setState({ exportingExcel: false });
      var win = window.open(_config.baseUrl + "/" + m.path, '_blank');
      win.focus();
    }).catch(m => {
      alert(m.error)
    })
  }
  doSearch = ({ target }) => {
    if (!this.state.searching) {

      this.setState({ searching: true })
      const { name, value } = target;
      let filteredUserList = this.state.userList;
      // let filteredUserList = JSON.parse(JSON.stringify(this.state.userList));
      let searchText = value.toLowerCase();
      if (filteredUserList && filteredUserList.length > 0) {
        filteredUserList = filteredUserList.filter(u =>
          (u.firstName && u.firstName.toLowerCase().includes(searchText)) ||
          (u.lastName && u.lastName.toLowerCase().includes(searchText)) ||
          (u.email && u.email.toLowerCase().includes(searchText)) ||
          (u.phoneNo && u.phoneNo.toLowerCase().includes(searchText)) ||
          (u.source && u.source.toLowerCase().includes(searchText))
        );
      }
      this.setState({ filteredUserList, searching: false })
    }



  }
  render() {
    // const userList = usersData
    const { userList, type, filteredUserList } = this.state;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xl={12}>
            <Card>
              <CardHeader>
                <div className="row">
                  <div className="col-sm-9">
                    <i className="fa fa-users"></i> Users
                    {type &&
                      <Link to="/users" className="btn btn-info btn-sm text-white ml-4">
                        Show All
                    </Link>
                    }
                  </div>
                  <div className="col-sm-3" style={{ textAlign: 'right' }}>
                    <button onClick={() => window.print()} className="btn btn-primary btn-sm">
                      <i className="fa fa-print"></i>
                    </button>
                    <button onClick={this.handleExportExcel} className="btn btn-primary btn-sm ml-1" disabled={this.state.exportingExcel}>
                      <i className="fa fa-file-excel-o" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>

              </CardHeader>
              <CardBody>
                <Field>
                  <input
                    name="search"
                    type="text"
                    placeholder="Search..."
                    disabled={this.state.searching}
                    onChange={e => this.doSearch(e)}
                  />
                </Field>
                <Table responsive hover>
                  <thead>
                    <tr>
                      {/* <th scope="col">id</th> */}
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col"></th>
                      <th scope="col">Phone Number</th>
                      <th scope="col"></th>
                      <th scope="col">Authentication</th>
                      <th scope="col">Agendas</th>
                      <th scope="col">Tasks</th>
                      <th scope="col">Verification Status</th>
                      <th scope="col">Availability</th>
                      <th scope="col">Status</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUserList &&
                      filteredUserList.length > 0 ?
                      filteredUserList
                        .slice(
                          (this.state.activePagination.selectedPage - 1) *
                          this.state.activePagination.pageSize,
                          this.state.activePagination.selectedPage *
                          this.state.activePagination.pageSize
                        )
                        .map((user, index) => (
                          <UserRow key={index} user={user} />
                        ))
                      :
                      <tr>
                        <td>No User Found!</td>
                      </tr>
                    }

                  </tbody>
                </Table>
                {
                  filteredUserList && <PaginationComponent
                    totalItems={filteredUserList.length}
                    pageSize={this.state.activePagination.pageSize}
                    onSelect={this.handleSelectedActivePage}
                    maxPaginationNumbers={
                      this.state.activePagination.maxPaginationNumbers
                    }
                    activePage={this.state.activePagination.selectedPage}
                  />
                }

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  // const { token, admin } = authentication;
  return {
    users
  };
}

const connected = connect(mapStateToProps)(Users);
export { connected as Users };
