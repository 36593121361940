const config = {
    baseUrl: '',
    // baseUrl: 'https://home-huntadmin.co.uk',
    // baseUrl: 'http://localhost:3000',
    // baseUrl: 'http://192.168.6.18:3000',
    googleMapAPIKey: "AIzaSyCX2y23lkwti4hjdH-OGJ2yrzTitQ6r_vY",
    mapZoom: 15,
    defaultLatLng: {
        lat: 6.522934,
        lng: 3.378868
    }
}
export default config;