export const adminConstants = {
    LOGIN_REQUEST: 'ADMINS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'ADMINS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'ADMINS_LOGIN_FAILURE',

    GET_CURRENT_REQUEST: 'ADMINS_GET_CURRENT_REQUEST',
    GET_CURRENT_SUCCESS: 'ADMINS_GET_CURRENT_SUCCESS',
    GET_CURRENT_FAILURE: 'ADMINS_GET_CURRENT_FAILURE',
    
    LOGOUT: 'ADMINS_LOGOUT'
};
