import React, { Component, useEffect } from 'react';
import Field from '../../../_common/field';
// import AddressLocationField from '../../../_ui_components/AddressLocationField';
import ImageUploader from '../../../_ui_components/ImageUploader';
import MultipleImageUploader from '../../../_ui_components/MultipleImageUploader';
import ParentCheckList from '../../../_ui_components/ParentCheckList';
import ParentDropDown from '../../../_ui_components/ParentDropDown';
import PasswordField from '../../../_ui_components/PasswordField';
import RTEField from '../../../_ui_components/RTEField';
import DateTimePicker from 'react-datetime-picker';
import DatePicker from "react-datepicker";
import RatingSelector from '../../../_ui_components/RatingSelector';
import config from '../../../_config';
import ChildGrid from '../../../_ui_components/ChildGrid';
import AddressLocationFieldJS from '../../../_ui_components/AddressLocationField/AddressLocationFieldJS';

const FieldsGenerator = (props) => {
    const { modelType, errors, formObj, handleChange, handleChangeBoolean, handleChageCustom, onchangeModelType, isLoading, className, handleChangeLocation } = props;
    const bluePrint = props.bluePrint ? props.bluePrint : modelType.bluePrint;

    return (
        <React.Fragment>
            {
                (formObj && bluePrint) && Object.keys(bluePrint).map((attribute, key) => {
                    const fclass = bluePrint[attribute]["fieldClass"] ? bluePrint[attribute]["fieldClass"] : className;
                    if (bluePrint[attribute] && !bluePrint[attribute].hidden && bluePrint[attribute].dataType !== "DefaultDate") {
                        if (bluePrint[attribute].dataType === "String" || bluePrint[attribute].dataType === "Number") {
                            return <Field label={bluePrint[attribute].label} errors={errors[attribute]} key={key} className={fclass}>
                                <input
                                    name={attribute}
                                    // type={bluePrint[attribute].dataType === "String" ? "text" : "number"}
                                    type="text"
                                    placeholder={bluePrint[attribute].placeholder}
                                    value={formObj[attribute]}
                                    disabled={isLoading || bluePrint[attribute].disabled}
                                    onChange={e => handleChange(e)}
                                />
                            </Field>
                        }
                        else if (bluePrint[attribute].dataType === "TextArea") {
                            return <div className={`form-group ${fclass}`}>
                                {bluePrint[attribute].label && !bluePrint[attribute].labelHidden && <label className="pb-1">{bluePrint[attribute].label}</label>}
                                <textarea name={attribute} rows="7" cols="25"
                                    className="form-control"
                                    placeholder={bluePrint[attribute].placeholder}
                                    value={formObj[attribute]}
                                    disabled={isLoading || bluePrint[attribute].disabled} onChange={e => handleChange(e)}></textarea>
                                {errors[attribute] ? <div className="alert alert-danger">{errors[attribute][0]}</div> : null}
                            </div>
                        }
                        else
                            if (bluePrint[attribute].dataType === "PasswordHash") {
                                return <Field label={bluePrint[attribute].label} errors={errors[attribute]} key={key} className={fclass}>
                                    <PasswordField
                                        name={attribute}
                                        // type={bluePrint[attribute].dataType === "String" ? "text" : "number"}
                                        placeholder={bluePrint[attribute].placeholder}
                                        value={formObj[attribute]}
                                        disabled={isLoading}
                                        onChange={value => handleChageCustom(attribute, value)}

                                    />
                                </Field>
                            } else if (bluePrint[attribute].dataType === "RichText") {
                                return <div className={`form-group ${fclass}`}>
                                    <label>{bluePrint[attribute].label}</label>
                                    <RTEField
                                        value={formObj[attribute]}
                                        onChange={value => handleChageCustom(attribute, value)}
                                    />
                                </div>
                            } else if (bluePrint[attribute].dataType === "AddressBar") {
                                return <Field label={bluePrint[attribute].label} errors={errors[attribute]} key={key} className={fclass}>
                                    <AddressLocationFieldJS
                                        name={attribute}
                                        placeholder={bluePrint[attribute].placeholder}
                                        value={formObj[attribute]}
                                        location={formObj[attribute + "Location"]}
                                        geoFence={formObj[attribute + "GeoFence"]}
                                        geoFenceBluePrint={bluePrint[attribute + "GeoFence"]}
                                        disabled={isLoading}
                                        onChange={(res) => {
                                            handleChangeLocation(res);

                                        }}
                                    />
                                </Field>
                            } else if (bluePrint[attribute].dataType === "EnumDropDown") {
                                return <Field label={bluePrint[attribute].label} errors={errors[attribute]} key={key} className={fclass}>
                                    <select
                                        name={attribute}
                                        value={formObj[attribute]}
                                        disabled={isLoading}
                                        onChange={e => handleChange(e)}
                                    >
                                        <option value={null}>{bluePrint[attribute].placeholder}</option>
                                        {bluePrint[attribute].enum.map(en => <option value={en}>{en}</option>)}
                                    </select>
                                </Field>
                            } else if (bluePrint[attribute].dataType === "ParentDropDown") {
                                return <ParentDropDown
                                    key={key}
                                    name={attribute}
                                    bluePrint={bluePrint[attribute]}
                                    value={formObj[attribute]}
                                    errors={errors[attribute]}
                                    className={fclass}
                                    disabled={isLoading}
                                    onChange={parentId => {
                                        Object.keys(bluePrint).forEach((at, indx) => {
                                            if (bluePrint[at].dataType && bluePrint[at].loadVia === attribute) {
                                                bluePrint[at]["loadViaId"] = parentId;
                                            }
                                        });
                                        onchangeModelType(modelType);
                                        handleChageCustom(attribute, parentId);
                                    }}
                                />

                            } else if (bluePrint[attribute].dataType === "ParentCheckList") {
                                return <ParentCheckList
                                    key={key}
                                    name={attribute}
                                    bluePrint={bluePrint[attribute]}
                                    value={formObj[attribute]}
                                    errors={errors[attribute]}
                                    disabled={isLoading}
                                    className={fclass}
                                    onChange={parentId => handleChageCustom(attribute, parentId)}
                                />
                            } else if (bluePrint[attribute].dataType === "MultipleImages") {
                                return <MultipleImageUploader
                                    key={key}
                                    name={attribute}
                                    bluePrint={bluePrint[attribute]}
                                    value={formObj[attribute]}
                                    errors={errors[attribute]}
                                    disabled={isLoading}
                                    className={fclass}
                                    onChange={parentId => handleChageCustom(attribute, parentId)}
                                />
                            } else if (bluePrint[attribute].dataType === "Image") {
                                return <div className="col-sm-6">
                                    <ImageUploader
                                        key={key}
                                        name={attribute}
                                        label={bluePrint[attribute].label}
                                        placeholder={bluePrint[attribute].placeholder}
                                        value={formObj[attribute]}
                                        errors={errors[attribute]}
                                        className={fclass}
                                        onChange={filePath => handleChageCustom(attribute, filePath)} />
                                </div>
                            } else if (bluePrint[attribute].dataType === "Boolean") {
                                return <div className={`form-group row ${fclass}`} key={key}>
                                    <div className="col-sm-3">
                                        <label>{bluePrint[attribute].label}: </label>
                                    </div>
                                    <div className="col-sm-9">
                                        <label class="switch">
                                            <input
                                                name={attribute}
                                                type={"checkbox"}
                                                // className="ml-3 mt-1"
                                                placeholder={bluePrint[attribute].placeholder}
                                                checked={formObj[attribute]}
                                                disabled={isLoading}
                                                onChange={e => handleChangeBoolean(e)}
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            } else if (bluePrint[attribute].dataType === "Date") {
                                return <Field label={bluePrint[attribute].label} labelBr={true} errors={errors[attribute]} key={key} className={fclass}>
                                    <DatePicker
                                        selected={formObj[attribute]}
                                        onChange={date => handleChageCustom(attribute, date)}
                                    />
                                </Field>
                            } else if (bluePrint[attribute].dataType === "DateTime") {
                                // return <Field label={bluePrint[attribute].label} labelBr={true} errors={errors[attribute]}>
                                return <div className={`form-group ${fclass}`}>
                                    <label>{bluePrint[attribute].label}</label>
                                    <br />
                                    <DateTimePicker
                                        value={formObj[attribute]}
                                        onChange={date => handleChageCustom(attribute, date)}
                                        key={key}
                                    />
                                </div>
                                // </Field>
                            } else if (bluePrint[attribute].dataType === "RatingSelector") {
                                return <div className={`form-group row ${fclass}`}>
                                    <div className="col-sm-3">
                                        <label>{bluePrint[attribute].label}</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <RatingSelector value={formObj[attribute]}
                                            onChange={val => handleChageCustom(attribute, val)} />
                                    </div>
                                </div>
                            } else if (bluePrint[attribute].dataType === "ChildGrid") {
                                return <ChildGrid
                                    key={key}
                                    name={attribute}
                                    bluePrint={bluePrint[attribute]}
                                    value={formObj[attribute]}
                                    errors={errors[attribute]}
                                    isLoading={isLoading}
                                    onChange={val => handleChageCustom(attribute, val)}
                                />
                            }
                            else
                                return null;
                    } else
                        return null;
                })

            }
        </React.Fragment>
    );
};

export default FieldsGenerator;